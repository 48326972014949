.social-share {
  position: fixed !important;
  top: 200px;
  right: 0px;

  #social-share-button {
    position: relative;
    display: block;
    background-color: #1c1c1c;
    width: 75px;
    height: 75px;
  }

  #social-share-button.hide {
    display: none;
  }
  #social-share-button.show {
    display: block;
  }

  #social-share-slide.show {
    display: block;
  }

  #social-share-slide.hide {
    display: none;
  }

  .share-button-icon {
    display: block;
    width: 26px;
    height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  #social-share-slide {
    display: none;
    background-color: #1c1c1c;
    img {
      display:block;
      margin:0 auto;
    }
    button {
      display:block;
      margin:0 auto;
    }
  }
  
  .share-results {
    display:block;
    color:#FFFFFF;
    font-family: rift, sans-serif;
    text-align:center;
    font-size:21px;
    font-weight:bold;
  }
  
  .grey-bar {
    width: 80%;
    border-bottom: 3px solid#313131;
    margin: 20px auto 20px auto;
  }
  ul {
    padding:0;
    list-style-type: none;
    li {
      padding: 20px;
    }
  }
}
