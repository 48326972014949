.home-page {
    background-repeat: no-repeat;
    background-color:#000000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  .remove-all-margin-padding {
    margin:0 ! important;
    padding:0 ! important;
  } 
  
  .row-full {
   width: 100vw;
   position: relative;
   margin-left: -50vw;
   left: 50%;
 }
  
  a.careers {
    font-weight:bold;
    text-decoration-thickness: .125em;
    text-underline-offset: 5.0px;
    text-decoration:underline;
    color:#FF888E;
    text-decoration-color: #FF888E;
    &:hover {
      color:#E41A24;
      text-decoration-color: #E41A24;
    }
  }
  .career-match {
    display: block;
    padding-top: 200px;
    margin: 0 auto;
    max-width: 576px;
  }
  
  .bg-color {
    background-color:#000000;
  }
  
  p {
    color: #ffffff;
    text-align: center;
    font-size: 19px;
    font-family:Open Sans;
    max-width:600px;
    margin-left:auto;
    margin-right:auto;
  }

  .col-height {
    display: block;
    max-height: 100%;
  }

  .button {
    display: block;
    height: 62px;
    width: 202px;
    margin: 30px auto 100px auto;
  }

  .button path {
    fill: #cd001b;
    stroke: #cd001b;
    stroke-width: 2px;
    transition: all 300ms ease-in;
  }

  .button tspan {
    fill: #fff;
  }

  .button:hover path {
    fill: #411517;
    stroke: #cd001b;
  }
  
  .bg-image {
    background-repeat:no-repeat;
    background-size:contain;
  }
  
  @media (min-width: 576px) {

  }

 // Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  .career-match {
    margin-top:200px;
  }
  
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .career-match {
    margin-top:200px;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
  .career-match {
    margin-top:200px;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }
  
}
