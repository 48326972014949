$header-color: #1F1F1F;
$header-opacity: 0.4;

$menu-color: #ffffff;
$menu-opacity: 0.2;

 .greetuser {
     color:#FFFFFF;
     font-size:19px;
  }
  
 .row-full{
   width: 100vw;
   position: relative;
   margin-left: -50vw;
   left: 50%;
 }

.main-header {
  .header {
    position: fixed;
    top: 0px;
    height: 125px;
    background-color: rgba($header-color, $header-opacity);
    width: 100%;
    z-index: 999999;
    backdrop-filter: blur(3px);
  }

  .logo {
    display: block;
    vertical-align: center;
    margin-left: 50px;
  }

  .hamburger {
    display: block;
    float: right;
    margin-right: 50px;
    cursor: pointer;
  }

  ul.nav-menu {
    list-style-type: none;
  }
  
  ul {
    list-style-type:none;
  }

  .bar {
    display: block;
    width: 50px;
    height: 5px;
    margin: 10px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #d23530;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(15px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-15px) rotate(-45deg);
  }

  .navbar {
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  }

  .nav-item {
    margin-left: 5rem;
  }

  .nav-link {
    font-size: 38px;
    font-weight: 400;
    color: #ffffff;
    border-bottom: 3px solid #FFF;
    padding-bottom: 3px;
    display: inline-block;
  }

  .hdr-md-wh {
    color: #ffffff;
    font-size: 38px;
  }

  .nav-menu {
    position:fixed;
    display: table;
    width: 100%;
    padding-top: 100px;
    z-index: -1;
    backdrop-filter: blur(8px);
    opacity:0;
    top: 8rem;
    flex-direction: column;
    top: 0px;
    bottom: 0px;
    text-align: center;
    transition: 0.4s;
    height: auto;
    background-color: rgba(#1F1F1F, 0.9);
  
    ul {
      padding:0px;
      display: table-cell;
      vertical-align: middle;
      li.nav-item {
        a:hover {
          color:#FF888E;
          border-bottom: 3px solid #FF888E;
        }
      }
    }
  }
  
  .follow-us {
    display:block;
    margin-top:100px;
    color:#FFFFFF;
    font-size:38px;
    font-weight:bold;
  }
  
  .social-icons {
    margin:0 auto;
    max-width:600px;
    svg {
      height:45px;
      width:auto;
      &:hover {
        filter: invert(59%) sepia(47%) saturate(680%) hue-rotate(308deg) brightness(108%) contrast(100%);
      }
    }
  }

  .nav-menu.active {
    opacity:0.9;
    z-index: 999999;
  }

  .nav-item {
    margin: 2.5rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  @media (max-width: 575.98px) {
    .logo {
      margin-left: 10px;
    }
    .hamburger {
      margin-right: 10px;
    }
    .follow-us {
      margin-top:100px; 
    }
    .nav-menu {
      padding-top: 20px;
    }
  }
  //#1F1F1F
  .trans-bg {
    content:"";
    background-color:red;
    width:100%;
    height:100%;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
  }
  
}
