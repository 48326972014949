.footer {
  height: 500px;
  background-color: #1c1c1c;

  .results-ftr-md-wh {
    display: block;
    color: #ffffff;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 21px;
    font-weight:bold;
  }
  
  .ftr-menu {
     margin:20px 0 20px 0;
     text-align:center;
     a {
       padding:20px;
       color:#FFFFFF;
       text-decoration:underline;
     }
  }

  .results-ftr-md-sm-wh {
    margin-top: 50px;
    font-size: 14px;
    color: #ffffff;
    display: block;
    text-align: center;
    margin:0 auto;
  }

  .results-ftr-wh {
    display: block;
    color: #ffffff;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 21px;
    margin-bottom: 20px;
    font-weight:bold;
  }
  
   .social-icons {
    margin:0 auto;
    max-width:600px;
    svg {
      height:30px;
      width:auto;
      &:hover {
        filter: invert(59%) sepia(47%) saturate(680%) hue-rotate(308deg) brightness(108%) contrast(100%);
      }
    }
  }

  .hd-ftr {
    margin-top: 100px;
  }
  
  .byf-logo {
     padding:50px; 
  }
  
  .gpc-logo {
     padding:25px 50px 50px 50px
  }

  ul {
    width:100%;
    align-items: center;
    display: inline-flex;
    list-style-type: none;
    li {
      padding: 20px;
      a {
        color: #ffffff;
        text-decoration: underline;
        text-align: center;
      }
    }
  }
  img {
    display: block;
    margin: 0 auto;
  }
  
  a {
    text-decoration-thickness: .125em;
    text-underline-offset: 2.0px;
    text-decoration: underline;
    color: #FF888E;
    text-decoration-color: #FF888E;
     &:hover {
      color:#E41A24;
      text-decoration-color: #E41A24;
    }
  }

 // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
     height:100%;
     
     .results-ftr-md-sm-wh {
      margin-top:50px; 
     }
  }
  
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
     height:100%;
  }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
     height:100%;
  }
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) and (max-width: 1199.98px) { }
  
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { }
}
