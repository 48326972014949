.question-one {
  
  .pb-wrapper {
     display:block;
      margin-left:auto;
      margin-right:auto;
      width:440px;
      margin-top:0px;
      margin-bottom:30px;
    .progressbar {
      display:block;
      div {
        display:inline-block;
        margin-right:10px;
      }
      .bar {
         background-color:#707070;
         width:100px;
         height:6px;
      }
      .redbar {
        background-color:#FF888E;
        width:100px;
         height:6px;
      }
    }
  }
  
  .form-title {
    display: block;
    color: #ffffff;
    font-size: 40px;
    font-family: rift, sans-serif;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    margin-bottom: 30px;
  }

  .assessment-two__background-image {
    background-size: cover;
    background-repeat: no-repeat;

  }
  .assessment-two__logo {
    display: block;
    margin-top:50px;
    margin-left:auto;
    margin-right:auto;
  }
  .assessment-two__question-count {
    display: block;
    font-size: 21px;
    text-align: center;
    color: #ffffff;
    font-family: rift, sans-serif;
    font-weight: bold;
    margin: 30px 0 30px 0;
  }
  .btn {
    display: block !important;
    margin: 0 auto;
  }
  .form-group {
    input {
      border-radius: 0;
      border-bottom: 5px solid #c7c7c7;
    }
    button,
    .btn {
      display: block;
      margin: 0 auto;
      background-color: transparent;
      border: 0px;
      outline: none !important;
      &:hover {
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
      &:focus {
        background-color: transparent;
        border: transparent !important;
        outline: none;
      }
      &:active {
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
      *:focus {
        outline: none;
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
      *:focus-visible {
        outline: none;
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
    }
  }
  .form-check-input {
    border: 1px solid #ffffff;
  }
  .form-check-inline {
    max-width: 576px;
    padding: 20px;
    margin: 20px;
    .form-check-label {
      color: #ffffff;
      font-size: 21px;
    }
  }
  .progress-bar {
    background-color: #ff888e;
  }

  .progress {
    margin-bottom: 30px;
  }
  .button {
    display: block;
    height: 62px;
    width: 202px;
    margin: 30px auto 30px auto;
  }

  .button path {
    fill: #cd001b;
    stroke: #cd001b;
    stroke-width: 2px;
    transition: all 300ms ease-in;
  }
  
  
  .radio__control {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid currentColor;
}

  .button tspan {
    fill: #fff;
  }

  .button:hover path {
    fill: #411517;
    stroke: #cd001b;
  }

  #video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .form-check {
    margin-left:auto;
    margin-right:auto;
    display:block;
  }
  
  .btn-primary:focus {
    box-shadow:none!important;
  }
  
   .progress {
    margin-bottom:20px;
  }
  
  input.fancy {
        opacity:0;
        border:0px;
        position:absolute;
        width: 100%;
        height:100%;
    }

    /*Style the following div*/
    input.fancy + div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 16px;
        padding: 0 15px 0 0;
        border: 2px solid #707070;
        cursor: pointer;
        width: 500px;
        height: 64px;
        user-select:none;
        transition: border-color 0.3s, background-color 0.3s;
    }
    
    /*Create a checkbox as a psuedo element on the following div*/
    input.fancy + div:before {
        content:'';
        display: block;
        min-width: 66px;
        max-width: 66px;
        height: 100%;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFIAAAA6CAMAAAAUVf6eAAAAjVBMVEUAAAD+/v7+/v7////////////+/v7+/v7+/v7////////+/v7+/v7////////////+/v7+/v7+/v7+/v7////////////+/v7+/v7+/v7////+/v7+/v7////////////////////+/v7+/v7+/v7+/v7+/v7+/v7+/v7////+/v7////////+/v7////PL7e+AAAALnRSTlMAChDx+ZMyHxX16nFo4LebTDcnGuXQinkYDr9kIt3Yy7Grhn5dWE4/K9NHoZ1SIs2dJgAAAYFJREFUWMPV1stygkAQheFuFBlEUUTxhhISFGKSef/HS7kghwSQWy/Mv3TgK4vSU5BkrJhkQeesz44karj6nsmy4j1PXNSOkLh71yClRe2JiwFLiP4G4uJJRUtenEM0xcVAXHx9UjEUFydTcXEE0REXj/9AnJVEvs2SsKOYPhTVQWttJ9xFXI4fiRzjefQQx9eK+cwPL1IiefmpfRMSydc/ptdKvBbEJVXFrs6bZs0gH3WdiKw1zLBRnEEcQfzbCv/+edhBnFB9Hn63ZzVMhIknHqlBIvrCte6+VnRaiOiCq2OjRnyrE5u/gclN4joj6mYG3CCumrTyPduSydv2ImKzaA4R0f4D9yW/T4IeIl6OMXUQF/h83lbEwOfZaaUY+dQxdSpPHfcSkQVzmpXETWcRs4Sp48+CuKNeZTBPFhmHgqioZ6mNqfMLorsjEjBtiC8GDQizJCUSJ2IizK20CBOrPDwONDL3JBGbEJlkMmIxEeYBolQqEhQxdQGTbNaxj/gNjp2auVi5xzUAAAAASUVORK5CYII=);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        border-right: 2px solid #707070;
        margin-right: 20px;
        user-select:none;
        transition: border-color 0.3s, background-color 0.3s;
    }

    /* When the input is checked*/
    input.fancy:checked + div {
        border-color:#E41A24;
        background-color:#310002;
    }
    input.fancy:checked + div:before {
        background-color:#E41A24;
        border-color:#E41A24;
    }
    
    form.was-validated {
      input.fancy + div {
        border-color:#E41A24;
        background-color:#310002;
      }
    }
    

    /* When the input is hovered over*/
    //input.fancy:hover + div {
    //    border-color:#E41A24;
    //    background-color:#310002;
    //}
    

    /* Desktop */
    @media screen and (min-width:1024px) {
        input.fancy + div {
            font-size: 21px;
            line-height: 26px;
            height: 96px;
        }
        
        /*Create a checkbox as a psuedo element on the following div*/
        input.fancy + div:before {
            min-width: 98px;
            max-width: 98px;
            background-size: 38px;
        }
    }
        
          // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
            input.fancy + div {
              width:300px;
            }
            
            .form-check-inline {
               margin:0px; 
            }
        }
        
        @media (max-width: 575.98px) { 
          
          .pb-wrapper {
              width:240px;
            .progressbar {
              .bar {
                 width:50px;
              }
              .redbar {
                width:50px;
              }
            }
          }
          
        }
      
        // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) { }
        
        // Medium devices (tablets, less than 992px)
        @media (max-width: 991.98px) { }
        
        // Large devices (desktops, less than 1200px)
        @media (max-width: 1199.98px) { }
        
        // X-Large devices (large desktops, less than 1400px)
        @media (max-width: 1399.98px) { }
  
}
