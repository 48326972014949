.entry-form {
  background-color:#000000;
  
  .main-header {
    position:absolute;
  }
  
  .form-title {
    display: block;
    color: #ffffff;
    font-size: 40px;
    font-family: rift, sans-serif;
    font-weight: 700;
    font-style: italic;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 150px;
  }
  .form-group {
    max-width: 576px;
    margin: 0 auto;
  }
  .assessment-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  
  .bg-form {
    background-color:#000000;
  }

  p {
    display:block;
    font-size: 19px;
    color: #ffffff;
    text-align: center;
    max-width:514px;
    margin:0 auto;
  }

  .form-group {
    input {
      border-radius: 0;
      border-bottom: 5px solid #c7c7c7;
    }
    button,
    .btn {
      display: block;
      margin: 0 auto;
      background-color: transparent;
      border: 0px;
      outline: none !important;
      &:hover {
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
      &:focus {
        background-color: transparent;
        border: transparent !important;
        outline: none;
      }
      &:active {
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
      *:focus {
        outline: none;
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
      *:focus-visible {
        outline: none;
        background-color: transparent !important;
        border: 0px !important;
        outline: none;
      }
    }
  }
  .form-check {
    display: block;
    margin: 20px 20px 20px 0;
    .form-check-label {
      color: #ffffff;
      margin-left: 20px;
    }
    input[type="checkbox"] {
      width: 25px;
      height: 25px;
    }
  }
  .career-match {
    display: block;
    padding-top: 60%;
    max-width: 640px;
    margin: 0 auto;
  }
  .button {
    display: block;
    height: 62px;
    width: 202px;
    margin: 30px auto 30px auto;
  }
  
  .btn-primary:focus {
    box-shadow:none!important;
  }

  .button path {
    fill: #cd001b;
    stroke: #cd001b;
    stroke-width: 2px;
    transition: all 300ms ease-in;
  }

  .button tspan {
    fill: #fff;
  }

  .button:hover path {
    fill: #411517;
    stroke: #cd001b;
  }
  
  .form-check-label {
    font-size:14px;
  }
  
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) { 
    .career-match {
      margin-top:400px;
    }
    .form-title {
      margin-top:25px!important;
    }
  }
  
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    .career-match {
      margin-top:200px;
    }
    .form-title {
      margin-top:25px!important;
    }
  }
  
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { 
    .career-match {
      margin-top:200px;
    }
    .form-title {
      margin-top:25px!important;
    }
  }
  
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) { }
  
}
