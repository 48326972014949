.results-page {
  .results-hd-sm-top {
    display: block;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 200px;
  }

  .red-bar {
    width: 200px;
    border-bottom: 3px solid#FF888E;
  }

  p {
    color: #ffffff;
    text-align: center;
  }

  .results-hd-sm {
    display: block;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 50px;
  }

  .results-hd-sm-cp {
    display: block;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
  }

  .grey-bar {
    width: 80%;
    border-bottom: 3px solid#313131;
    margin: 50px auto 50px auto;
  }

  .results-hd-lg-wh {
    display: block;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 58px;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    margin-top: 30px;
  }

  .results-hd-md-wh {
    display: block;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 40px;
    font-style: italic;
    font-weight: bold;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .results-hd-md-bl {
    display: block;
    text-align: center;
    font-family: rift, sans-serif;
    font-size: 40px;
    font-style: italic;
    font-weight: bold;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .bg-wh {
    background-color: #ffffff;
  }

  .bg-blk {
    background-color: #000000;
  }

  .slider-bg {
    background-color: #000000;
  }

  .carousel {
    .carousel-caption {
      max-width:1464px;
      margin-left:auto;
      margin-right:auto;
      top:30%;
      h3 {
        text-align:left;
        font-size: 40px;
        font-family: rift, sans-serif;
        font-weight: bold;
        margin-left:50px;
      }
      p {
        text-align:left;
        font-family:Open Sans;
        font-size:16px;
        line-height:32px;
        margin-left:50px;
        max-width:500px;
      }
      
    }
    .carousel-item {
        img {
          width:auto;
          margin-left:auto;
          margin-right:auto;
          opacity:0.5;
      }
    }
  }
  .button {
    display: block;
    height: 62px;
    width: 202px;
    margin: 30px auto 30px auto;
  }

  .button path {
    fill: #cd001b;
    stroke: #cd001b;
    stroke-width: 2px;
    transition: all 300ms ease-in;
  }

  .button tspan {
    fill: #fff;
  }

  .button:hover path {
    fill: #411517;
    stroke: #cd001b;
  }
  .carousel-indicators {
    bottom: -90px !important;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    transform: translate(0, 340px);
    color:#F08E91;
    
  }
  
  .carousel-control-prev-icon {
    background-image:url('../svg/Arrow_L.svg');
    width:60px;
    height:60px;
  }
  
   .carousel-control-next-icon {
    background-image:url('../svg/Arrow_R.svg');
    width:60px;
    height:60px;
  }
  
  .carousel-indicators li {
    width: 70px !important;
  }

  .carousel-indicators li.active {
    background-color: #ff888e;
  }

  .arrow-down {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .next-step {
    margin-top: 200px;
  }
  
  .results-ftr-wh {
     margin-top:80px; 
  }
  
  .footer {
    .ftr-menu {
       a {
        font-weight:bold;
        text-decoration-color: #FFFFFF;
        text-decoration-thickness: .125em;
        text-underline-offset: 5.0px;
        &:hover {
          color:#FF888E;
          text-decoration-color: #FF888E;
        }
       } 
    }
  }
  
  
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
       .results-hd-sm-top {
      margin-top: 130px;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      transform: translate(0, 200px);
    }
    .next-step {
      margin-top: 100px;
    }
    
    .carousel-control-prev-icon {
      width:40px;
      height:40px;
    }
  
   .carousel-control-next-icon {
      width:40px;
      height:40px;
    }
    
    .carousel-indicators {
      bottom: -70px !important;
    }
    
    .carousel-caption {

      top:10%!important;
      h3 {
        font-size: 25px!important;
        margin-left:20px!important;
      }
      p {
        line-height:28px!important;
        margin-left:20px!important;
      }
      
    }
    
    
  }
  
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
     .results-hd-sm-top {
      margin-top: 130px;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      transform: translate(0, 200px);
    }
    .next-step {
      margin-top: 100px;
    }
    
    .carousel-control-prev-icon {
      width:40px;
      height:40px;
    }
  
   .carousel-control-next-icon {
      width:40px;
      height:40px;
    }
    
    .carousel-indicators {
      bottom: -70px !important;
    }
    
    .carousel-caption {
      top:10%!important;
      h3 {
        font-size: 35px!important;
        margin-left:20px!important;
      }
      p {

        margin-left:20px!important;
      }
      
    }
  }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {  }
  
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) and (max-width: 1199.98px) { }
  
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { }

}
