@import "~bootstrap/scss/bootstrap";

#root {
    height:100%;
}

.container-fluid {
    background-color:#000000;
}

.main-bg-color {
    background-color:#000000;
}

.remove-all-margin-padding{
    margin:0 ! important;
    padding:0 ! important;
}