.career-cards {
  .career-card {
    border: 2px solid #c7c7c7;
    width: 100%;
    height: 286px;
    max-width: 331px;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    img {
      display:block;
      margin-top:-50px;
      margin-left:auto;
      margin-right:auto;
    }
  }

  .job-position {
    display: block;
    font-size: 19;
    font-weight: bold;
    text-align:center;
    margin-top:-40px;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .career-card {
      width: 100%;
    }
  }

  .see-details {
    display: block;
    margin-top: 10px;
    color: #f08e91;
    text-decoration: underline;
    font-weight: bold;
    text-align:center;
  }
  
  
}
